.error-announce-cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // font-family: SFPRODISPLAYREGULAR, Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
  //   'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  font-weight: normal;
  color: #212529;

  .input-dot {
    border-style: dotted;
    border-width: 0 0 1px;
    box-shadow: none;
    border-radius: 0 !important;
    padding: 8px 0 6px;
    height: 30px;
    // margin-top: 6px;
    border-color: #999;
    min-width: 200px;
  }

  .input-date {
    background: url(../../../../assets/icons/ic_calendar.svg) no-repeat right;
    background-size: 20px;
  }

  .tax-code {
    .tax-code-input {
      .tax-code-number:first-child {
        border-left: 1px solid #ccc;
      }
      .tax-code-number {
        width: 30px;
        line-height: 24px;
        height: 28px;
        text-align: center;
        font-family: SFProBold !important;
        font-weight: 700;
        border: 1px solid #ccc;
        justify-content: center;
        padding-top: 2px;
        border-left: none;
      }
    }
  }

  .ant-table-tbody > tr > td {
    padding: 0 !important;
  }
}

.error-announce {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 90%;
  margin-bottom: 100px;
  border-radius: 4px;
}
