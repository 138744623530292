.DetailControl {
  font-size: 14px;

  .dropdown {
    &:not(.dd-font-size) {
      .dropdown-toggle {
        border: none;
      }
    }

    .dropdown-toggle {
      background-color: transparent !important;
    }

    .dropdown-menu {
      width: auto;
    }
  }

  .dropdown.dd-font-size {
    .dropdown-toggle {
      height: 26px;
      width: 50px;
      padding: 0 10px 0 4px;
    }

    .dropdown-menu {
      width: 50px !important;
      min-width: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);

      max-height: 350px;
      overflow: scroll;

      .dropdown-item {
        // padding: 0.25rem 1rem;
      }
    }
  }

  .dropdown.dd-align-type,
  .dropdown.dd-display-type,
  .dropdown.dd-display-border-type {
    .dropdown-toggle {
      padding: 0.5rem 0.5rem;
    }
    .dropdown-menu {
      width: auto;
    }
  }

  .dropdown.dd-th-color,
  .dropdown.dd-text-color {
    .dropdown-toggle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0.5rem;
    }

    .dropdown-menu {
      width: auto;
    }
  }

  .dd-add-line {
    .dropdown-toggle {
      width: 100%;
    }
    .dropdown-menu {
      width: 100%;
      padding: 0;
      box-shadow: 0 1px 6px rgb(0, 0, 0, 40%);
      border-radius: 0 0 0.42rem 0.42rem;
      overflow: hidden;

      .dropdown-item {
        color: #5099ef;
        text-align: center;

        &:first-child {
          border-right: 1px solid #ccc;
        }

        &:hover {
          background-color: #e7f3ff;
        }
      }

      .dropdown-divider {
        height: 20px;
        border: 0;
        border-left: 1px solid #ccc;
      }
    }
  }

  .tool-dashboard {
    background: #f5f5f5;
    padding: 8px 0px 4px;

    .divide {
      height: 22px;
      margin: auto 2px;
      border-right: 1px solid #5e666f;
    }
  }

  .bold-type,
  .italic-type,
  .align-right-type,
  .align-left-type,
  .align-center-type,
  .font-size-plus,
  .font-size-minus,
  .field-delete {
    padding: 0.5rem 0.65rem;
  }

  .mst-type {
    padding: 0.5rem 0.5rem;
  }

  .lineheight-up,
  .lineheight-down {
    padding: 5px 3px 5px;
    div {
      background-position: -3px center;
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;

      width: 25px;
      height: 25px;
    }
  }
  .breakline,
  .mergeline {
    padding: 5px 3px 5px;
    div {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;

      width: 25px;
      height: 25px;
    }
  }

  .display-type {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: content-box;

    width: 25px;
    height: 25px;
  }

  .mergeline-gif-container {
    .mergeline {
      padding: 5px 3px 5px;
      div {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-clip: content-box;

        height: 25px;
      }
    }
    &:hover .mergeline-gif {
      display: block;
    }

    .mergeline-gif {
      z-index: 999;
      right: 0;

      display: none;
      border-radius: 5px;
      border: 1px solid #cfd9e0;
      overflow: hidden;
      // transition: visibility 0s, opacity 0.5s linear;

      .mergeline-gif-note {
        height: 60px;
        padding: 10px 10px 10px 15px;
      }
    }
  }

  .data-field-input {
    &.active {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #a6d5fa !important;
      border-color: #2196f3;
    }

    &:read-only {
      background-color: #f0f2f5;
    }
  }

  .disable {
    opacity: 0.3;
    cursor: default !important;
    pointer-events: none;
  }

  .accordion-button,
  .accordion-button:not(.collapsed) {
    background-color: inherit;
  }
}
