// Change tooltip arrow color - bootstrap 5
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: white;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: white;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: white;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: white;
}

// Bootstrap DatetimePicker Widget
.bootstrap-datetimepicker-widget table tbody {
  border-top: 0 !important;
}

// Bootstrap button active
// .btn {
//     border-color: transparent !important;
// }

// Date range picker
.daterangepicker {
  padding: 0;
  margin: 0;
  width: auto;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.42rem;
  border: 0;
  font-family: Poppins, Helvetica, 'sans-serif';
  z-index: 98;
}
.modal-open .daterangepicker {
  z-index: 1051;
}
.daterangepicker:after,
.daterangepicker:before {
  display: none;
}
.daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 175px;
}
.daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: #7e8299;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.daterangepicker .ranges li:hover,
.daterangepicker .ranges li.active {
  background-color: #f3f6f9;
  color: #3699ff;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ebedf3;
  margin-top: 0;
  height: 297px;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0;
}
.daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid #ebedf3;
}
.daterangepicker .drp-buttons .btn {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.42rem;
}
.daterangepicker .drp-selected {
  font-size: 0.9rem;
}
.daterangepicker .drp-calendar.left,
.daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.daterangepicker .drp-calendar.left {
  border-left: 0 !important;
}
.daterangepicker .drp-calendar th,
.daterangepicker .drp-calendar td {
  font-size: 1rem;
  font-weight: regular;
  width: 33px;
  height: 33px;
}
.daterangepicker .drp-calendar th {
  font-weight: 500;
  color: #3f4254;
}
.daterangepicker .drp-calendar th.month {
  font-weight: 500;
  color: #3f4254;
}
.daterangepicker .drp-calendar th.next span,
.daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #7e8299;
}
.daterangepicker .drp-calendar th.next span {
  margin-right: 1px;
}
.daterangepicker .drp-calendar th.prev span {
  margin-left: 1px;
}
.daterangepicker .drp-calendar td {
  color: #7e8299;
}
.daterangepicker .drp-calendar td:hover {
  background-color: #f3f6f9;
}
.daterangepicker .drp-calendar td.available.off {
  color: #b5b5c3;
}
.daterangepicker .drp-calendar td.active {
  background-color: #3699ff !important;
  color: #ffffff !important;
  border-radius: 0.42rem;
}
.daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 0.42rem;
}
.daterangepicker .drp-calendar td.today,
.daterangepicker .drp-calendar td.today.active {
  border-radius: 0.42rem;
  background: rgba(54, 153, 255, 0.12) !important;
  color: #3699ff !important;
}
.daterangepicker
  .drp-calendar
  td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #f3f6f9;
  color: #3f4254;
}
.daterangepicker select {
  border-radius: 0.42rem;
  background: transparent !important;
  border-color: #e4e6ef !important;
  color: #3f4254 !important;
}

@media (min-width: 730px) {
  .daterangepicker.show-calendar .ranges {
    height: 297px !important;
  }
}

@media (max-width: 767.98px) {
  .daterangepicker.show-calendar .ranges {
    height: 245px;
  }
}

.example-highlight pre {
  &:focus-visible {
    outline: none;
  }
}

// Form input feedback visible
.invalid-feedback {
  display: block !important;
}

.valid-feedback {
  display: block !important;
}

// Disable body document scroll
.body-overflow-hidden {
  overflow: hidden !important;
}

// Enable body document scroll
.body-overflow-auto {
  overflow: auto !important;
}

// Remove input [type=number] caret up down
.no-caret-updown-input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
// ---end---

// Custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d6efd;
}

// KTBlockUI
.blockui {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: normal;
}

.blockui-overlay {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.text-remaining {
  color: #616161 !important;
}

.swal2-icon {
  margin: 2.5em auto 0.6em !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

.q-max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.q-max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.q-max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.q-max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.custom-hover-cell {
  background-color: #f3f6f9;
  position: sticky !important;
  right: 0;
  border: none !important;
}

.rdt_TableRow {
  position: relative !important;
}

.badge-light-success {
  color: #20a144;
  background-color: #28a0431a;
  border-color: transparent;
}

.badge-light-primary {
  color: #5180fb;
  background-color: #5180fb1a;
  border-color: transparent;
}

.badge-light-danger {
  color: #ee2334;
  background-color: #ff57571a;
  border-color: transparent;
}

.badge-light-warning {
  color: #e18700;
  background-color: #d5853b1a;
  border-color: transparent;
}

.aside-menu .menu-nav > .menu-item > .menu-link {
  padding: 9px 15px !important;
}

.brand {
  padding: 0px 15px !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside {
    width: 50px;
  }
}

.text-grey {
  color: #535362 !important;
}

.topbar {
  align-items: center !important;
}

.text-grey {
  color: #3c4376 !important;
}

.card-custom {
  box-shadow: 0px 8px 24px 0px #252a5b0d !important;
  border: 1px solid #e8e9eb !important;
  border-radius: 20px !important;
}

.btn {
  font-weight: 600;
  border-radius: 5px !important;
}

.ant-spin-container {
  font-family: 'Inter' !important;
}

.dropdown-menu {
  // box-shadow: 0px 5px 10px 0px #2c3f5826 !important;
  border-radius: 14px;
}

.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  color: #3c4376 !important;
}

.text-dark-75 {
  color: #3c4376 !important;
}

.custom-select {
  color: #3c4376 !important;
}

.navi .navi-item .navi-link .navi-icon i {
  color: #3c4376;
}

.modal-content {
  border-radius: 14px;
}

.switch input:empty ~ span:before {
  border: 1px solid #dbe3ef !important;
}

.form-control:active,
.form-control.active,
.form-control:focus,
.form-control.focus {
  box-shadow: 0px 0px 4px 0px rgba(61, 103, 255, 0.5) !important;
}

.custom-dropdown-toggle:active,
.custom-dropdown-toggle.active,
.custom-dropdown-toggle:focus,
.custom-dropdown-toggle.focus {
  box-shadow: 0px 0px 4px 0px rgba(61, 103, 255, 0.5) !important;
  border: 1px solid #5180fb !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-link {
  padding: 0px 20px;
  padding-left: 35px;
}

.card-header {
  padding: 1rem 1.25rem !important;
}

.btn.btn-clean i {
  color: #3c4376;
}

.aside-fixed.aside-minimize:not(.aside-minimize-hover) .wrapper {
  padding-left: 50px;
}

.aside-fixed.aside-minimize.aside-minimize-hover .wrapper {
  padding-left: 50px;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-link {
  padding: 0 25px;
  padding-left: 50px;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-section {
  padding-left: 50px;
}

@media (min-width: 992px) {
  .header-fixed.aside-minimize .header {
    left: 50px;
  }
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside > .aside-support {
    display: none;
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: transparent !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: white;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-arrow {
  color: white;
}

.rdt_TableCol_Sortable > div {
  white-space: normal !important;
}

// Change tooltip arrow color - bootstrap 5
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: white;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: white;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: white;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: white;
}

// Bootstrap DatetimePicker Widget
.bootstrap-datetimepicker-widget table tbody {
  border-top: 0 !important;
}

// Bootstrap button active
// .btn {
//     border-color: transparent !important;
// }

// Date range picker
.daterangepicker {
  padding: 0;
  margin: 0;
  width: auto;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.42rem;
  border: 0;
  font-family: Poppins, Helvetica, 'sans-serif';
  z-index: 98;
}
.modal-open .daterangepicker {
  z-index: 1051;
}
.daterangepicker:after,
.daterangepicker:before {
  display: none;
}
.daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 175px;
}
.daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: #7e8299;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.daterangepicker .ranges li:hover,
.daterangepicker .ranges li.active {
  background-color: #f3f6f9;
  color: #3699ff;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ebedf3;
  margin-top: 0;
  height: 297px;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0;
}
.daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid #ebedf3;
}
.daterangepicker .drp-buttons .btn {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.42rem;
}
.daterangepicker .drp-selected {
  font-size: 0.9rem;
}
.daterangepicker .drp-calendar.left,
.daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.daterangepicker .drp-calendar.left {
  border-left: 0 !important;
}
.daterangepicker .drp-calendar th,
.daterangepicker .drp-calendar td {
  font-size: 1rem;
  font-weight: regular;
  width: 33px;
  height: 33px;
}
.daterangepicker .drp-calendar th {
  font-weight: 500;
  color: #3f4254;
}
.daterangepicker .drp-calendar th.month {
  font-weight: 500;
  color: #3f4254;
}
.daterangepicker .drp-calendar th.next span,
.daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #7e8299;
}
.daterangepicker .drp-calendar th.next span {
  margin-right: 1px;
}
.daterangepicker .drp-calendar th.prev span {
  margin-left: 1px;
}
.daterangepicker .drp-calendar td {
  color: #7e8299;
}
.daterangepicker .drp-calendar td:hover {
  background-color: #f3f6f9;
}
.daterangepicker .drp-calendar td.available.off {
  color: #b5b5c3;
}
.daterangepicker .drp-calendar td.active {
  background-color: #3699ff !important;
  color: #ffffff !important;
  border-radius: 0.42rem;
}
.daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 0.42rem;
}
.daterangepicker .drp-calendar td.today,
.daterangepicker .drp-calendar td.today.active {
  border-radius: 0.42rem;
  background: rgba(54, 153, 255, 0.12) !important;
  color: #3699ff !important;
}
.daterangepicker
  .drp-calendar
  td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #f3f6f9;
  color: #3f4254;
}
.daterangepicker select {
  border-radius: 0.42rem;
  background: transparent !important;
  border-color: #e4e6ef !important;
  color: #3f4254 !important;
}

@media (min-width: 730px) {
  .daterangepicker.show-calendar .ranges {
    height: 297px !important;
  }
}

@media (max-width: 767.98px) {
  .daterangepicker.show-calendar .ranges {
    height: 245px;
  }
}

.example-highlight pre {
  &:focus-visible {
    outline: none;
  }
}

// Form input feedback visible
.invalid-feedback {
  display: block !important;
}

.valid-feedback {
  display: block !important;
}

// Disable body document scroll
.body-overflow-hidden {
  overflow: hidden !important;
}

// Enable body document scroll
.body-overflow-auto {
  overflow: auto !important;
}

// Remove input [type=number] caret up down
.no-caret-updown-input {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
// ---end---

// Custom scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d6efd;
}

// KTBlockUI
.blockui {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: normal;
}

.blockui-overlay {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.text-remaining {
  color: #616161 !important;
}

.swal2-icon {
  margin: 2.5em auto 0.6em !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

.q-max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.q-max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.q-max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.q-max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.custom-hover-cell {
  background-color: #f3f6f9;
  position: sticky !important;
  right: 0;
  border: none !important;
}

.rdt_TableRow {
  position: relative !important;
}

.badge-light-success {
  color: #20a144;
  background-color: #28a0431a;
  border-color: transparent;
}

.badge-light-primary {
  color: #5180fb;
  background-color: #5180fb1a;
  border-color: transparent;
}

.badge-light-danger {
  color: #ee2334;
  background-color: #ff57571a;
  border-color: transparent;
}

.badge-light-warning {
  color: #e18700;
  background-color: #d5853b1a;
  border-color: transparent;
}

.badge-light-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: transparent;
}

.badge-light-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: transparent;
}

.aside-menu .menu-nav > .menu-item > .menu-link {
  padding: 9px 15px !important;
}

.brand {
  padding: 0px 15px !important;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside {
    width: 50px;
  }
}

.text-grey {
  color: #535362 !important;
}

.topbar {
  align-items: center !important;
}

.text-grey {
  color: #3c4376 !important;
}

.card-custom {
  box-shadow: 0px 8px 24px 0px #252a5b0d !important;
  border: 1px solid #e8e9eb !important;
  border-radius: 20px !important;
}

.btn {
  border-radius: 6px !important;
}

.ant-spin-container {
  font-family: 'Inter' !important;
}

.dropdown-menu {
  // box-shadow: 0px 5px 10px 0px #2c3f5826 !important;
  border-radius: 14px;
}

.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  color: #3c4376 !important;
}

.text-dark-75 {
  color: #3c4376 !important;
}

.custom-select {
  color: #3c4376 !important;
}

.navi .navi-item .navi-link .navi-icon i {
  color: #3c4376;
}

.modal-content {
  border-radius: 14px;
}

.switch input:empty ~ span:before {
  border: 1px solid #dbe3ef !important;
}

.form-control:active,
.form-control.active,
.form-control:focus,
.form-control.focus {
  box-shadow: 0px 0px 4px 0px rgba(61, 103, 255, 0.5) !important;
}

.custom-dropdown-toggle:active,
.custom-dropdown-toggle.active,
.custom-dropdown-toggle:focus,
.custom-dropdown-toggle.focus {
  box-shadow: 0px 0px 4px 0px rgba(61, 103, 255, 0.5) !important;
  border: 1px solid #5180fb !important;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-link {
  padding: 0px 20px;
  padding-left: 35px;
}

.card-header {
  padding: 1rem 1.25rem !important;
}

.btn.btn-clean i {
  color: #3c4376;
}

.aside-fixed.aside-minimize:not(.aside-minimize-hover) .wrapper {
  padding-left: 50px;
}

.aside-fixed.aside-minimize.aside-minimize-hover .wrapper {
  padding-left: 50px;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-link {
  padding: 0 25px;
  padding-left: 50px;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-item
  > .menu-submenu
  .menu-subnav
  > .menu-section {
  padding-left: 50px;
}

@media (min-width: 992px) {
  .header-fixed.aside-minimize .header {
    left: 50px;
  }
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside > .aside-support {
    display: none;
  }
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: transparent !important;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: white;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-arrow {
  color: white;
}

.rdt_TableCol_Sortable > div {
  white-space: normal !important;
}
