.NotificationModal {
  .modal-header {
    border-bottom: 0;
    padding: 1rem 1.5rem 0rem;
    color: #333 !important;

    .modal-title {
      font-size: 20px !important;
      font-weight: 650 !important;
    }
  }
  .modal-body {
    padding: 0 1.5rem;
    font-size: 14px;
    color: #333 !important;
    font-weight: 400;
  }
  .modal-footer {
    border-top: 0;
    padding: 0.4rem 1.5rem 1rem;
  }
}
