.KTFormInput {
  .form-control ~ .input-group-prepend {
    border-color: #304ffd;
    box-shadow: 0px 0px 6px 0px #40afffcc !important;
  }
  .form-control {
    border-radius: 12px;
    &:focus {
      border-color: #304ffd;
      box-shadow: 0px 0px 6px 0px #40afffcc !important;
    }
  }

  .input-group-text {
    border-radius: 12px;
  }

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > .form-floating:not(:last-child)
    > .form-control,
  .input-group:not(.has-validation)
    > .form-floating:not(:last-child)
    > .form-select,
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
    ) {
    // border-top-right-radius: 12px;
    // border-bottom-right-radius: 12px;
  }

  .form-invalid {
    border-color: #e54135;
    box-shadow: 0px 0px 6px 0px #e54135cc !important;
  }
}
