.ticketFooter {
  width: 100%;
  height: 60px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px;
  border-top: 1px solid #ccc;
}

.ticketTotalPrice {
  width: 100%;
  height: 40px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
  padding-right: 20px;
}
