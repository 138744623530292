.CarItem {
  border-bottom: 1px solid #dbe3ef;
  &:first-child {
    border-top: 1px solid #dbe3ef;
  }

  .ItemAmount {
    &-button,
    &-amount {
      height: 30px;
      width: 30px;
      line-height: 31px;
      color: #666e82;
    }
    &-button {
      i {
        font-size: 13px;
        color: #666e82;
      }
      &:hover {
        background: #eee;
      }
    }
  }
  .DeleteItemButton {
    i {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  td {
    padding: 1rem 1.5rem !important;
  }
  .text-tooltip {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */
  }
  p {
    min-width: max-content;
  }
}
