.popover-arrow {
  display: none !important;
}

.GoodTable {
  .rdt_TableBody {
    max-height: 15rem !important;
    overflow-y: auto;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    border-right: none !important;
  }

  .rdt_TableRow {
    border-bottom: none !important;
  }
}

.custom-button.addNewGoodBtn {
  width: 100%;
  max-width: 300px;
}
