.MainControl {
  font-size: 14px;

  .adjust-info-hover {
    &:hover {
      cursor: pointer;
      background-color: #e7f3ff;
      color: #1492e6;
    }
  }

  .wrap-button {
    background-color: #f0f2f5;
    border-radius: 6px;
    padding: 0;
    height: 36px;

    .button {
      padding: 6px 0;
      width: 176px;
      text-align: center;

      &.active {
        border-radius: 4px;
        background-color: #fff;
        height: 100%;
        box-shadow: 0 1px 3px rgb(0 0 0/10%);
        font-weight: 700;
      }
    }
  }
}
