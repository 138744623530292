.Payment {
  .paymentBanner {
    position: relative;
    width: 100%;
    height: 170px;
    background-image: url('/assets/images/img_payment_banner.png');
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stepBox {
    position: absolute;
    bottom: 0 !important;
    background: #fff;
    transform: translateY(50%);
    padding: 10px;
    border-radius: 15px;
    .imgBx {
      min-width: 31px;
      min-height: 31px;
      max-width: 31px;
      max-height: 31px;
      // padding: 5px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .btnCustom1 {
    height: 40px;
    background: #fff;
    color: #3c4376;
    font-weight: 600;
    border: 1px solid #dbe3ef;
    &:hover {
      background: #dbe3ef;
    }
  }
  .btnCustom2 {
    height: 40px;
    background: #5180fb;
    color: #fff;
    font-weight: 600;
    border: none;
    &:hover {
      background: rgb(67, 140, 248);
    }
  }
  .customBtn {
    border: 1px solid #dbe3ef;
    border-radius: 5px;
    &:hover {
      background: #dbe3ef;
    }
  }
}
