.ButtonGroupTab {
  .buttonActive {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #304ffd;
  }
  .buttonInactive {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
