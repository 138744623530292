.ModalDataTransmissionLog {
  width: 700px !important;

  .modal-header {
    padding: 1.25rem;
  }

  .modal-body,
  .modal-footer {
    padding: 0;
  }

  .modal-body {
    line-height: 1.4;
    font-size: 14px;
    color: #333;
  }
}
