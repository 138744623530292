@media (min-width: 992px) {
  .header-menu .menu-nav > .menu-item > .menu-link {
    background-color: none;
  }
  .header-menu
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #9096b8;
  }
  .header-menu
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #9096b8;
  }
  .header-menu .menu-nav > .menu-item > .menu-link .menu-text {
    color: #9096b8;
  }
  .header-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
    color: #6c7293;
  }
  .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: none;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link {
    background-color: none;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-text {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link,
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link {
    background-color: none;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-text,
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-text {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    > .menu-arrow,
  .header-menu
    .menu-nav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu .menu-nav > .menu-item .menu-submenu {
    background-color: #242939;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link {
    background-color: transparent;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-icon {
    color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-text {
    color: #a2a3b7;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item
    > .menu-link
    .menu-arrow {
    color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-active
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-here
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-text,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    > .menu-arrow,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    > .menu-item {
    border-right: 1px solid rgba(73, 75, 116, 0.25);
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-heading
    > .menu-text {
    color: #a2a3b7;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-heading
    > .menu-icon {
    color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link {
    background-color: transparent;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-icon {
    color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-text {
    color: #a2a3b7;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item
    > .menu-link
    .menu-arrow {
    color: #494b74;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-active
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-here
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-text,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item:hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    > .menu-arrow,
  .header-menu
    .menu-nav
    > .menu-item
    .menu-submenu
    > .menu-subnav
    .menu-content
    .menu-inner
    > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active)
    > .menu-link
    > .menu-arrow {
    color: #3699ff;
  }
}

@media (max-width: 991.98px) {
  .header-menu-mobile {
    background-color: #242939;
  }
  .header-menu-mobile .menu-nav > .menu-item > .menu-heading .menu-text,
  .header-menu-mobile .menu-nav > .menu-item > .menu-link .menu-text {
    color: #a2a3b7;
  }
  .header-menu-mobile .menu-nav > .menu-item > .menu-heading .menu-icon,
  .header-menu-mobile .menu-nav > .menu-item > .menu-link .menu-icon {
    color: #494b74;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #4b5575;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #4b5575;
  }
  .header-menu-mobile .menu-nav > .menu-item > .menu-heading .menu-arrow,
  .header-menu-mobile .menu-nav > .menu-item > .menu-link .menu-arrow {
    color: #4b5575;
  }
  .header-menu-mobile .menu-nav > .menu-item.menu-item-open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile .menu-nav > .menu-item.menu-item-open > .menu-heading,
  .header-menu-mobile .menu-nav > .menu-item.menu-item-open > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile .menu-nav > .menu-item.menu-item-here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile .menu-nav > .menu-item.menu-item-here > .menu-heading,
  .header-menu-mobile .menu-nav > .menu-item.menu-item-here > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile .menu-nav > .menu-item.menu-item-active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile .menu-nav > .menu-item.menu-item-active > .menu-heading,
  .header-menu-mobile .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading,
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile .menu-nav > .menu-item > .menu-arrow {
    color: #242939;
  }
  .header-menu-mobile .menu-nav > .menu-section .menu-text {
    color: #4c4e6f;
  }
  .header-menu-mobile .menu-nav > .menu-section .menu-icon {
    color: #4c4e6f;
  }
  .header-menu-mobile .menu-nav > .menu-separator {
    border-bottom: 1px solid #4c4e6f;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-text {
    color: #9899ac;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-icon {
    color: #494b74;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #4b5575;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #4b5575;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item
    > .menu-link
    .menu-arrow {
    color: #4b5575;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-open
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-here
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item.menu-item-active
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link {
    background-color: #1f2433;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-text,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-text {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon {
    color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #3699ff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-arrow,
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-arrow {
    color: #ffffff;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-section
    .menu-text {
    color: #4c4e6f;
  }
  .header-menu-mobile
    .menu-nav
    > .menu-item
    .menu-submenu
    .menu-section
    .menu-icon {
    color: #4c4e6f;
  }
  .header-menu-mobile .menu-nav > .menu-item .menu-submenu .menu-separator {
    border-bottom: 1px solid #4c4e6f;
  }
}

@media (max-width: 991.98px) {
  .header-menu-wrapper {
    background-color: #242939;
  }
  .header-menu-wrapper.ps > .ps__rail-x {
    background-color: transparent;
  }
  .header-menu-wrapper.ps > .ps__rail-x:hover,
  .header-menu-wrapper.ps > .ps__rail-x:focus {
    opacity: 1;
    background-color: transparent;
  }
  .header-menu-wrapper.ps > .ps__rail-x:hover > .ps__thumb-x,
  .header-menu-wrapper.ps > .ps__rail-x:focus > .ps__thumb-x {
    opacity: 1;
  }
  .header-menu-wrapper.ps > .ps__rail-x > .ps__thumb-x {
    background-color: #637099;
    opacity: 1;
  }
  .header-menu-wrapper.ps > .ps__rail-x > .ps__thumb-x:hover,
  .header-menu-wrapper.ps > .ps__rail-x > .ps__thumb-x:focus {
    opacity: 1;
    background-color: #637099;
  }
  .header-menu-wrapper.ps > .ps__rail-y {
    background-color: transparent;
  }
  .header-menu-wrapper.ps > .ps__rail-y:hover,
  .header-menu-wrapper.ps > .ps__rail-y:focus {
    background-color: transparent;
    opacity: 1;
  }
  .header-menu-wrapper.ps > .ps__rail-y:hover > .ps__thumb-y,
  .header-menu-wrapper.ps > .ps__rail-y:focus > .ps__thumb-y {
    opacity: 1;
  }
  .header-menu-wrapper.ps > .ps__rail-y > .ps__thumb-y {
    background: #637099;
    opacity: 1;
  }
  .header-menu-wrapper.ps > .ps__rail-y > .ps__thumb-y:hover,
  .header-menu-wrapper.ps > .ps__rail-y > .ps__thumb-y:focus {
    opacity: 1;
    background: #637099;
  }
}
