.daterangepicker {
  margin-top: 10px !important;
  font-family: inherit !important;
}

.DateRangePickerInput {
  // max-width: 230px;
  // background: linear-gradient(180deg, #ffffff 0%, #f8f9ff 100%);
  border: 1px solid #dbe3ef;
  border-radius: 4px;
  user-select: none;

  span {
    color: hsl(0, 0%, 20%) !important;
  }
  input {
    // width: 100%;
    // height: 100%;
    border: none;

    &:focus {
      outline: none;
    }

    font-weight: 500;
    color: var(--remaining-color);
  }

  &:hover {
    border-color: #b3bfd2;
  }

  // &:active {
  //   background: linear-gradient(180deg, #f8f9ff 0%, #f1f2f5 100%);
  // }
}
@media (max-width: 1280px) {
  .DateRangePickerInput {
    min-width: 120px;
  }
}
@media (max-width: 751px) {
  .DateRangePickerInput {
    min-width: 158px;
    margin-right: 6px;
  }
}
.ranges {
  overflow: auto;
  // ul {
  //   box-shadow:
  //     0 0 0 1px hsla(0, 0%, 0%, 0.1),
  //     0 4px 11px hsla(0, 0%, 0%, 0.1);
  // }
  ul li {
    &:hover {
      background-color: #f3f6f9 !important;
    }
    &:active {
      background-color: #f3f6f9 !important;
    }
    &:focus {
      background-color: #f3f6f9 !important;
    }
  }
}
// .daterangepicker .ranges li:hover,
// .daterangepicker .ranges li.active {
//   color: #7e8299 !important;
//   background: #fff !important;
// }
