.custom-dropdown {
  border-radius: 4px;
  position: absolute;
  max-height: calc(100vh - 55vh);
  // height: 400px;
  width: 800px;
  background-color: white;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  z-index: 90;
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  // padding: 1rem;
}
