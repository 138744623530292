$min-height: 40px;
$font-size: 1rem;
$color: hsl(233, 33%, 35%);
$padding-left: 0px;
$border-bottom-style: solid;
$border-bottom-width: 1px;
$border-bottom-color: #ebedf3;
$border: 1px solid #ebedf3;
$background-color: #f3f6f9;
$font-weight: 600;
$text-transform: uppercase;
$letter-spacing: 0.1rem;
$line-height: 1.5 !important;
$padding: 0 1rem;
$border-right: 1px solid #ebedf3;
$border-top: 1px solid #ebedf3;
$color-hover: hsl(233, 33%, 21%);

.list-invoice {
  .dropdown {
    z-index: 30;
  }

  .gevHqG {
    color: black;
    background-color: #f4f5f8;
  }

  .hKEnmS {
    text-transform: unset;
    font-size: 1rem;
  }

  .btn-icon-custom {
    background-color: transparent !important;
    color: #3f4254 !important;
    border: none !important;

    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    &:hover {
      background-color: #d1e6fe !important;
    }
  }

  .btn-outline-primary {
    margin-top: 1px;
    border-radius: 2rem;

    &:hover {
      background-color: rgba(33, 150, 243, 0.04) !important;
      color: #3699ff !important;
      border-color: #3699ff;
      i {
        color: #3699ff !important;
      }
    }
  }

  .action-dd {
    .dropdown-menu {
      position: fixed !important;

      padding-top: 0;
      padding-bottom: 0;
    }

    .dropdown-divider {
      margin: 0;
    }
  }
  .hover {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    background-color: #f3f6f9;
  }

  .card-body {
    flex: 1 !important;
  }
}
