.ticketTableContainer {
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  color: #212529;
  .ticketCreateTable {
    width: 100%;
  }

  .ticketTableHeader {
    font-size: 18px;
    th {
      padding-bottom: 15px;
    }
  }
}
