$circle-size: 25;
$width: 400;
$background-color: Thistle;

.ticket-layout {
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  //   box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  cursor: pointer;

  .ticket-header {
    display: flex;
    justify-content: start;
    color: #212529;
    padding: 6px 12px;
    // border-bottom: 1px dashed #ccc;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .holes {
    position: relative;
    margin: 5px 12px;
    border: 1px dashed #ccc;

    &:before {
      content: '';
      height: $circle-size + px;
      width: $circle-size + px;
      background-color: #f2f7ff;
      position: absolute;
      border-radius: 50%;
      border: 1px solid #ccc;
      border-bottom-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg);
      left: -25px;
      top: -12px;
    }

    &:after {
      content: '';
      height: $circle-size + px;
      width: $circle-size + px;
      background-color: #f2f7ff;
      position: absolute;
      border-radius: 50%;
      border: 1px solid #ccc;
      border-bottom-color: transparent;
      border-left-color: transparent;
      transform: rotate(-135deg);
      right: -25px;
      top: -12px;
    }
  }

  &:active {
    opacity: 0.6;
  }
  &:hover {
    border-color: #1877f2;
    .ticket-header {
      color: #1877f2;
    }

    .holes {
      &:after {
        border-color: #1877f2;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
      &:before {
        border-color: #1877f2;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
    }
  }

  .ticket-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #212529;
  }

  .serial {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
  }

  .price {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
  }
}

.ticket-content {
  padding: 10px 12px;
  color: white; /* Text color */
  text-align: center;
}
