.adjustReplaceTicket-select {
  .ant-select-selector {
    padding: 0.25rem 1rem !important;
  }
}


.AdjustReplaceTicketModal {
  .form-group {
    margin-bottom: 0;
  }
}