.LogoControl {
  font-size: 14px;

  .selected-color {
    width: 20px;
    height: 20px;
  }

  .logo-dropzone {
    border: 2px dashed #ced0d4;
    padding: 15px 0;
    text-align: center;
    height: 185px;
    border-radius: 10px;
  }

  .logo-preview,
  .custom-logo-preview {
    border: 1px solid #ddd;
    border-right: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .logo-preview {
    height: 185px;
    width: 230px;
  }

  .custom-logo-preview {
    height: 145x;
    width: 150px;
  }

  .setting-logo-position,
  .setting-custom-logo-position {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #ddd;
    border-left: none;
    background: #f0f2f5;
  }

  .setting-logo-position {
    width: 137px;
    height: 185px;
  }

  .setting-custom-logo-position {
    width: 210px;
    height: 145px;
  }
}
