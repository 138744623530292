.folderTreeContainer {
  // width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  z-index: 10;

  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
}

.create-invoice-cover {
  // background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem !important;
  // font-family: SFPRODISPLAYREGULAR, Roboto, Helvetica, Arial, sans-serif,
  //   'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  font-weight: normal;
  color: #3c4376;

  .input-table-cover {
    height: 40px;
  }

  .tax-code {
    .tax-code-input {
      .tax-code-number:first-child {
        border-left: 1px solid #ccc;
      }
      .tax-code-number {
        width: 30px;
        line-height: 24px;
        height: 28px;
        text-align: center;
        // font-family: SFProBold !important;
        font-weight: 700;
        border: 1px solid #ccc;
        justify-content: center;
        padding-top: 2px;
        border-left: none;
      }
    }
  }

  .ant-table-tbody > tr > td {
    padding: 0 !important;
  }

  .select--is-disabled {
    background-color: #f2f3f7;
    height: 40px;
  }

  .ant-table thead th:nth-child(1) {
    padding: 16px 0;
  }
}

.create-invoice {
  // background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  // width: 80%;
}

.create-invoice-left-col {
  display: flex;
  flex-direction: column;
  width: 75% !important;
  gap: 10px;
}

.create-invoice-right-col {
  display: flex;
  flex-direction: column;
  width: 20% !important;
  gap: 10px;
}

.icon-table {
  padding: 2px;
}

.line-break {
  display: flex;
  line-break: auto;
  width: 10px;
}
