.modal-backdrop {
  z-index: 50 !important;
}

.ModalUploadInvoiceExcel {
  z-index: 51 !important;
  .modal-header {
    border-bottom: 0;
    padding: 16px 1.5rem;
    color: #333 !important;

    .modal-title {
      font-size: 20px !important;
      font-weight: 650 !important;
    }
  }
  .modal-body {
    padding: 0 1.5rem;
    font-size: 14px;
    color: #333 !important;
    font-weight: 400;
  }

  .modal-footer {
    border-top: 0;
    padding: 1.4rem 1.5rem;
  }

  .uin-container {
    background: #b3e5fc;
    border: solid #0891cf;
    border-width: 0 0 0 6px;
    color: #044868;
    border-radius: 6px;

    .uin-wrapper {
      padding: 1.25em 1em;
    }
  }
}

.rs-kh__menu {
  .rs-kh__menu-list {
    padding-top: 0 !important;
  }
  .rs-kh__option {
    padding: 0 !important;
  }
}
