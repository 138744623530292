.ResourceManagement {
  padding: 0 !important;
  .blueBtn {
    &:hover {
      background: #ccedfe;
      border: solid 1px #ccedfe;
    }
  }
  .greenBtn {
    &:hover {
      background: #ccfeeb;
      border: solid 1px #ccfeeb;
    }
  }
  .purpleBtn {
    &:hover {
      background: #e9ccfe;
      border: solid 1px #e9ccfe;
    }
  }

  .customCard {
    & .ant-card-head {
      border-bottom: none;
    }
    & .ant-card-body {
      padding-top: 0;
      padding-bottom: 0.75rem;
    }
  }
}
