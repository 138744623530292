.SystemConfiguration {
  font-weight: 500;
  font-size: 13.5px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .KTFormInput {
    .form-control {
      border-radius: 5px !important;
    }
  }

  .card-custom {
    border-radius: 0px !important;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .boxTime {
    position: relative;
    .accordion-button {
      padding: 3px !important;
      z-index: 9;
    }
    border-radius: 3px;
    padding: 3px !important;
    .miniBox {
      background: #e0e0e0;
      width: fit-content;
      width: 70px;
      height: 30px;
      // padding: 4px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .downBox {
      border: 0.5px solid #e3e3e3;
      &:hover {
        background: #e0e0e0;
      }
    }
  }

  .miniBox2 {
    // background: #e0e0e0;
    width: fit-content;
    width: 70px;
    height: 30px;
    // padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 5px;
    &:hover {
      background: #e0e0e0;
    }
  }

  .dropdowTimeBox {
    max-height: 100px;
    overflow: auto;
  }

  .saveFooter {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
  }

  .cancelBtn {
    width: 120px;
    font-size: 14px;
  }

  .saveBtn {
    // float: right;
    // margin-right: -20px;
    width: 120px;
    font-size: 14px;
  }
  & .footerCard {
    position: sticky;
    bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    background-color: white;
    padding-top: 1rem;
  }
}
