.SubStep {
  .form-control {
    border-radius: 8px !important;
  }
  .plusIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #ecebeb;
    }
  }
}
