.ModalViewInvoice {
  font-size: 14px;
  color: #495057;

  .modal-dialog {
    width: 1200px;
    max-width: 100vw;

    .modal-content {
      height: 95vh;

      .modal-header {
        border-bottom: 0;

        .modal-title {
          font-size: 1.4rem !important;
          line-height: 1.4rem !important;
          font-weight: 700 !important;
        }

        .btn-icon {
          opacity: 0.5;
          height: inherit;

          &:hover {
            opacity: 1;
          }
        }
      }

      .modal-body {
        padding: 0;
      }

      .modal-footer {
        border-top: 0;
      }
    }
  }

  .p-title {
    font-size: 1.125em;
    font-weight: 500;
  }

  .p-item {
    font-size: 0.9em;
  }
}
