#kt_header {
  transition: background-color 0.3s ease-in-out;

  .menu-arrow::before {
    content: '\f105' !important;
    font-family: 'Font Awesome 5 Pro' !important;
    font-size: 1rem !important;
  }

  .topbar-item > .btn-icon.enable-filter {
    & > img {
      filter: invert(9%) sepia(13%) saturate(404%) hue-rotate(278deg)
        brightness(93%) contrast(89%) !important;
    }

    &:hover {
      & > img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(163deg)
          brightness(104%) contrast(101%) !important;
      }
    }
  }

  .menu-submenu > .menu-subnav > .menu-item > .menu-link {
    & > .menu-bullet {
      color: #494b74;
    }
    & > .menu-icon > img {
      filter: invert(28%) sepia(9%) saturate(2697%) hue-rotate(199deg)
        brightness(92%) contrast(81%);
    }

    &:hover {
      & > .menu-icon > img {
        filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg)
          brightness(100%) contrast(102%);
      }

      & > .menu-bullet {
        color: #3699ff;
      }
    }
  }

  .menu-submenu > .menu-subnav > .menu-item-open > .menu-link {
    & > .menu-icon > img {
      filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg)
        brightness(100%) contrast(102%);
    }
  }

  .menu-submenu > .menu-subnav > .menu-item-hover > .menu-link {
    & > .menu-icon > img {
      filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg)
        brightness(100%) contrast(102%);
    }
  }

  .border-icon {
    width: 1px;
    background-color: #e8e9eb;
    height: 34px;
  }
}
