.AppDeleteModal {
  .icon-badge {
    width: 130px;
    height: 130px;
    background-color: white;
    border: 10px solid #f2f3f7;
    border-radius: 45px;
    padding: 16px;
    gap: 10px;

    .far {
      font-size: 70px;
      color: #9ea5bd;
      align-items: center;
      align-self: center;
    }
  }

  .btn-success {
    background-color: #3ace59 !important;
    border: 1px solid #2ab448 !important;
  }
}
