.Kyhieu-toolTip {
  font-family: inherit !important;
  .tooltip-inner {
    text-align: left;
    min-width: 100%;
    max-width: 20rem;
  }
  .tooltip-arrow {
    display: none !important;
  }
}

.rs-kyhieu__menu {
  .rs-kyhieu__menu-list {
    padding-top: 0 !important;
  }
  .rs-kyhieu__option {
    padding: 0 !important;
  }
}
