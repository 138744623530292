$padding: 1rem 1.5rem;

.CardPieChartV2,
.CardInvoiceStatus {
  & .card-body {
    padding: $padding;
  }
}

.CardInvoiceStatus {
  & .card-header {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-bottom: 0 !important;
  }
  & .card-body {
    padding: 2rem !important;
  }
}
