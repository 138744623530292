$fontFamily:
  'Inter',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif !important;

.tab-dashboard-item {
  margin-bottom: 0 !important;
  min-width: 10rem;
  width: 12rem;
  font-family: $fontFamily;
  font-weight: 600;
  & .tab-dashboard-item-label {
    color: #3c4376 !important;
    font-size: 14px !important;
  }
}
