.listDraftTicketDropdown {
  width: 200px;
  //   height: 200px;
  //   background-color: red;
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  font-family: 'Inter' !important;
  border-radius: 5px;

  & > .body {
    max-height: calc(100vh - 50vh);
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    & > .draftTicketDropdownItem {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;

      padding: 8px 12px;
      // background-color: #e7f3ff !important;
      &:hover {
        background-color: #e7f3ff;

        .ticketInfo {
          .ticketName {
            color: #1877f2;
          }
        }

        .ticketQuantity {
          .iconDelete {
            display: block;
          }
        }
      }

      .ticketInfo {
        display: flex;
        flex-direction: column;
        align-items: start;
        .ticketName {
          font-weight: 700;
          font-size: 14px;
        }

        .ticketDate {
          font-weight: 500;
          font-size: 12px;
        }
      }
      .ticketQuantity {
        display: flex;
        flex-direction: column;
        align-items: end;
        font-weight: 500;
        font-size: 12px;
        .iconDelete {
          display: none;
        }
      }
    }
  }

  & > .footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 100%;
    color: #ff0000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter' !important;
    outline: none !important;
    // letter-spacing: 0px;
    gap: 6px;
    padding: 1rem 0;
  }
}
