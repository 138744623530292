.create-ticket-screen {
  display: flex;
  flex-direction: row;
  //   width: 100%;
  height: 100%;
  color: #212529;
  // overflow-y: hidden;

  .left-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
    // max-width: 350px;
    width: 500px;
    gap: 15px;
    overflow-y: scroll;

    .left-column-header {
      padding: 10px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      height: 60px;
      box-shadow: 0 0 6px rgb(0, 0, 0, 10%);
      //   background-color: red;
    }

    .ticket-container {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      gap: 15px;
    }
  }
  .right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: relative;
    background-color: white;
    width: 100%;

    .right-column-header-name {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 16px;
    }
    .right-column-header {
      height: 60px;
      padding: 12px;
      background-color: #f0f2f5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .group-button {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
