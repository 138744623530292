.menu-arrow::before {
  content: '\f105' !important;
  font-family: 'Font Awesome 5 Pro' !important;
  font-size: 1rem !important;
}

.aside > .brand {
  background-color: #182748 !important;
}

.aside-menu .menu-nav > .menu-item:hover {
  & > .menu-link > i {
    color: #00bbb3 !important;
  }
}

// Brand toggle
.brand-toggle {
  &:hover > .svg-icon > img {
    // filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%) !important;
    transition: all 0.15s ease;
  }
}
.brand-toggle.active {
  & > .svg-icon > img {
    // filter: invert(59%) sepia(68%) saturate(3862%) hue-rotate(189deg) brightness(100%) contrast(102%) !important;
    transition: all 0.15s ease;
    transform: rotate(180deg);
  }
}

// --- Custom ---
.aside {
  background-color: #182748 !important;
}

.aside .aside-menu.ps > .ps__rail-x {
  background-color: transparent;
}

.aside .aside-menu.ps > .ps__rail-x:hover,
.aside .aside-menu.ps > .ps__rail-x:focus {
  opacity: 1;
  background-color: transparent;
}

.aside .aside-menu.ps > .ps__rail-x:hover > .ps__thumb-x,
.aside .aside-menu.ps > .ps__rail-x:focus > .ps__thumb-x {
  opacity: 1;
}

.aside .aside-menu.ps > .ps__rail-x > .ps__thumb-x {
  background-color: #637099;
  opacity: 1;
}

.aside .aside-menu.ps > .ps__rail-x > .ps__thumb-x:hover,
.aside .aside-menu.ps > .ps__rail-x > .ps__thumb-x:focus {
  opacity: 1;
  background-color: #637099;
}

.aside .aside-menu.ps > .ps__rail-y {
  background-color: transparent;
}

.aside .aside-menu.ps > .ps__rail-y:hover,
.aside .aside-menu.ps > .ps__rail-y:focus {
  background-color: transparent;
  opacity: 1;
}

.aside .aside-menu.ps > .ps__rail-y:hover > .ps__thumb-y,
.aside .aside-menu.ps > .ps__rail-y:focus > .ps__thumb-y {
  opacity: 1;
}

.aside .aside-menu.ps > .ps__rail-y > .ps__thumb-y {
  background: #637099;
  opacity: 1;
}

.aside .aside-menu.ps > .ps__rail-y > .ps__thumb-y:hover,
.aside .aside-menu.ps > .ps__rail-y > .ps__thumb-y:focus {
  opacity: 1;
  background: #637099;
}

.aside-menu {
  background-color: #182748;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: #fff;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  color: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: white;
}

.aside-menu
  .menu-nav
  > .menu-item
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: white;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
  color: white;
}

.aside-menu .menu-nav > .menu-item.menu-item-open {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: #182748;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item.menu-item-open
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item.menu-item-here {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-link {
  background-color: #334670;
}

.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-text {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item.menu-item-here
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-here > .menu-link .menu-arrow {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item.menu-item-active {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #334670;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: #00bbb3;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-arrow,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-arrow {
  color: #00bbb3;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link {
  background-color: #334670;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: #00bbb3;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-arrow {
  color: #00bbb3;
}

.aside-menu .menu-nav > .menu-item > .menu-arrow {
  color: #182748;
}

.aside-menu .menu-nav > .menu-section .menu-text {
  color: #fff;
}

.aside-menu .menu-nav > .menu-section .menu-icon {
  color: #fff;
}

.aside-menu .menu-nav > .menu-separator {
  border-bottom: 1px solid #4b5575;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-text {
  color: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-icon {
  color: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #fff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item
  > .menu-link
  .menu-arrow {
  color: #4b5575;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link {
  background-color: #334670;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-text {
  color: #ffffff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-open
  > .menu-link
  .menu-arrow {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-here {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link {
  background-color: #334670;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link
  .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-here
  > .menu-link
  .menu-arrow {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link {
  background-color: #334670;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-text {
  color: #00bbb3;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #00bbb3;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  .menu-arrow {
  color: #ffffff;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover {
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link {
  background-color: #334670;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-text,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-text {
  color: #00bbb3;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon {
  color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-icon.svg-icon
  svg:hover
  g
  [fill],
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-icon.svg-icon
  svg:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-bullet.menu-bullet-dot
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-bullet.menu-bullet-dot
  > span {
  background-color: #00bbb3;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-bullet.menu-bullet-line
  > span,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-bullet.menu-bullet-line
  > span {
  background-color: #182748;
}

.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-heading
  .menu-arrow,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover
  > .menu-link
  .menu-arrow {
  color: #ffffff;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-section .menu-text {
  color: #fff;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-section .menu-icon {
  color: #fff;
}

.aside-menu .menu-nav > .menu-item .menu-submenu .menu-separator {
  border-bottom: 1px solid #4b5575;
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item {
    background: transparent;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item
    > .menu-link
    > .menu-icon {
    color: #fff;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-hover,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-open {
    background: transparent !important;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-hover
    > .menu-link
    > .menu-icon,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-open
    > .menu-link
    > .menu-icon {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-here,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-active {
    background: transparent !important;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-here
    > .menu-link
    > .menu-icon,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item.menu-item-active
    > .menu-link
    > .menu-icon {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item:hover {
    background: transparent;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    > .menu-item:hover
    > .menu-link
    > .menu-icon {
    color: #182748 !important;
  }
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-text,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-text {
    color: #3f4254;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-icon,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-icon {
    color: #b5b5c3;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #b5b5c3;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #b5b5c3;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #b5b5c3;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-arrow,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-heading
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item
    > .menu-link
    .menu-arrow {
    color: #b5b5c3;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link {
    background-color: #f3f6f9;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-text,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-text {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-arrow,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-heading
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-open
    > .menu-link
    .menu-arrow {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link {
    background-color: #f3f6f9;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-here
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link {
    background-color: #f3f6f9;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-text,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-text {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-arrow,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-heading
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item.menu-item-active
    > .menu-link
    .menu-arrow {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link {
    background-color: #f3f6f9;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-text,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-text {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-icon.svg-icon
    svg:hover
    g
    [fill],
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-icon.svg-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-dot
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-dot
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-bullet.menu-bullet-line
    > span,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-bullet.menu-bullet-line
    > span {
    background-color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-arrow,
  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-heading
    .menu-arrow,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
      .menu-item-here
    ):not(.menu-item-active):hover
    > .menu-link
    .menu-arrow {
    color: #182748;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-section
    .menu-text,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-section
    .menu-text {
    color: #7e8299;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-section
    .menu-icon,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-section
    .menu-icon {
    color: #b5b5c3;
  }

  .aside-minimize:not(.aside-minimize-hover)
    .aside-menu
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-separator,
  .aside-menu.aside-menu-dropdown
    .menu-nav
    .menu-item:not([data-menu-submenu-mode='accordion']).menu-item-hover
    > .menu-submenu
    .menu-subnav
    .menu-separator {
    border-bottom: 1px solid #ebedf3;
  }
}
