.CreateTicketReport {
  .ticket-report-container {
    // background-color: #f0f2f5;
    // font-family: SFPRODISPLAYREGULAR, Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    //   'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 1.1rem;
    font-weight: normal;
    color: #495057;

    .ticket-report-paper {
      background-color: #fff;
      width: 1000px;
      min-height: 100vh;
      padding: 0 24px 24px;

      .ticket-report-label {
        min-width: 150px;
      }

      .ticket-report-label-bold {
        min-width: 150px;
        font-weight: 700;
      }
    }

    .input-dot {
      border-style: dotted;
      border-width: 0 0 1px;
      box-shadow: none;
      border-radius: 0 !important;
      padding: 12px 0 6px;
      height: 30px;
      margin-top: 6px;
      border-color: #999;
      min-width: 250px;
    }

    .input-date {
      background: url(../../../../assets/icons/ic_calendar.svg) no-repeat right;
      background-size: 20px;
    }

    .tax-code {
      .tax-code-input {
        .tax-code-number:first-child {
          border-left: 1px solid #ccc;
        }
        .tax-code-number {
          width: 30px;
          line-height: 24px;
          height: 28px;
          text-align: center;
          font-family: SFProBold !important;
          font-weight: 700;
          border: 1px solid #ccc;
          justify-content: center;
          padding-top: 2px;
          border-left: none;
        }
      }
    }

    .react-select-container {
      .react-select__control {
        border-style: dotted;
        border-width: 0 0 1px;
        box-shadow: none;
        border-radius: 0 !important;
        padding: 12px 0 6px;
        height: 30px;
        margin-top: 6px;
        border-color: #999;
      }
    }

    .taxpayer {
      line-height: 25px;

      .form-check {
        margin-bottom: 0.5rem;
      }
    }

    .form-check-input[disabled]:not(:checked),
    .form-check-input:disabled:not(:checked) {
      opacity: 0.9;
      background-color: #f3f3f3;
    }
    .form-check-input[disabled] ~ .form-check-label,
    .form-check-input:disabled ~ .form-check-label {
      color: inherit;
      opacity: 0.9;
    }

    .digital-certificate {
      table {
        color: #495057;
        th,
        td {
          vertical-align: middle;
          text-align: center;
          padding: 0.5rem !important;
        }

        td {
          i {
            color: #495057;
          }
        }
      }
    }
  }

  .registration-footer {
    position: sticky;
    bottom: 0;

    width: 100%;
    z-index: 10;
    background-color: #f3f6f9;
  }
}
