.BaseTextFieldNoFormik {
  &_Input {
    height: 100%;

    &:focus {
      outline: 1px solid #3699ff;
      border: 1px solid #3699ff;
    }
  }

  &_Group {
    height: 40px;
    border: 1px solid #dbe3ef;

    &:focus-within {
      border: 1px solid #dbe3ef;
    }
  }

  &_Disabled {
    border: none;
    background: #f2f3f7;

    ::placeholder {
      font-weight: 500;
      color: #4a5677;
    }
  }

  input::-ms-reveal {
    display: none;
  }
  .number-input {
    width: 60px;
    text-align: right;
  }
}
