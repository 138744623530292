.table-red-header,
.table-header-normal {
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ebedf3;
  border-left: 1px solid #ebedf3;
  color: #5180fb;
}

.table-red-header {
  color: #f64e60;
}

.table-header-normal {
  border-right: 1px solid #ebedf3;
}

.list-replaced-invoice {
  .dropdown {
    z-index: 30;
  }

  .gevHqG {
    color: black;
    background-color: #f4f5f8;
  }

  .hKEnmS {
    text-transform: unset;
    font-size: 1rem;
  }

  .btn-icon-custom {
    background-color: transparent !important;
    color: #3f4254 !important;
    border: none !important;

    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    &:hover {
      background-color: #d1e6fe !important;
    }
  }

  .btn-outline-primary {
    margin-top: 1px;
    border-radius: 2rem;

    &:hover {
      background-color: rgba(33, 150, 243, 0.04) !important;
      color: #3699ff !important;
      border-color: #3699ff;

      i {
        color: #3699ff !important;
      }
    }
  }

  .action-dd {
    .dropdown-menu {
      position: fixed !important;

      padding-top: 0;
      padding-bottom: 0;
    }

    .dropdown-divider {
      margin: 0;
    }
  }
}
