.table-error-header,
.table-primary-header {
  font-weight: 600;
  justify-content: center;
  border: 1px solid #ebedf3;
  border-bottom: 0 !important;
  // padding: 0.5rem 1rem;

  display: flex;
  font-size: 15px;
  align-items: center;
  height: 45px;
}

.table-error-header {
  color: #e54135;
}

.table-primary-header {
  color: #304ffd;
  border-left: 0 !important;
}

#rowGroupContainer {
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 2;
}

#rowGroupContainer + div.rdt_TableHead {
  top: 45px !important;
}
