.importTicketStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .stepNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-weight: 600;
  }

  .stepDescription {
    position: absolute;
    top: 40px;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
  }
}
