.SurroundBackgroundSelect {
  .bg-item,
  .sr-item {
    width: 130px;
    height: 183px;
    background-size: 100%;
    background-repeat: no-repeat;

    background-position: center;
    cursor: pointer;

    &.active {
      border: 2px solid #1a8beb !important;
      box-shadow: -1px 1px 5px 5px #ddd;
    }
  }

  .sr-item {
    border: 1px solid #5c646d7d;
  }
}
