.CancelInvoiceModal {
  .vr-span {
    height: 70%;
    width: 0;
    border-right: 1px solid #c1c1c1;
  }

  .text-blue {
    color: #1877f2;
  }
}
