@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/* Custom fonts */

@import './assets/font_awesome_6_pro//css/all.css';
/* fontawesome */

@import './assets/keenthemes-icons/ki.css';
/* ki */

@import './assets/flaticon2/flaticon.css';
/* flaticon2 */

@import '~perfect-scrollbar';
/* perfect scrollbar */

html,
body {
  /* font size of root element (rem) */
  font-size: 13px !important;
}

body {
  margin: 0;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;

  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-toggle.btn:after {
  display: none !important;
}

body {
  /* font-family: SFPRODISPLAYREGULAR !important; */
  color: #3c4376 !important;
}

:root {
  --remaining-color: #616161;
}

.font-sfpro-bold {
  font-weight: bold;
}
.menu-item-active {
  background-color: #334670 !important;
}
.menu-item-active .menu-text {
  color: #00bbb3 !important;
}
.menu-item-open > a {
  background-color: #334670 !important;
}
.menu-item-open > a > .menu-text {
  color: #00bbb3 !important;
}
