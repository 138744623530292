.addStepBtn {
  margin-top: 10px;
  padding: 5px;
  width: fit-content;
  &:hover {
    background: #ecebeb;
  }
}
.ModalEdit {
}
