.InvoicepackageScreen {
  .mySwiper {
    // padding: 0px 65px;
    .swiper-slide {
      height: auto;
    }
    @media screen and (max-width: 546px) {
      padding: 0px 45px;
    }

    .swiper-button-prev {
      left: 0px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      height: 110%;
      top: 0;
      right: 0;
      background: transparent;
      padding: 0 15px;
      width: 50px;
      // box-shadow: 0px 50px 20px rgba(0, 0, 0, 0.05);
      @media screen and (max-width: 576px) {
        width: 35px;
      }
      &::after {
        background: #777e91;
        border-radius: 50%;
        color: #fff;
        font-size: 0.875rem;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        // padding: 19px 19px;
        // @media screen and (max-width: 564px) {
        //   padding: 10px 10px;
        // }
      }
    }
  }
  .swiper-pagination {
    position: relative !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0px !important;
  }
  .headName {
    font-weight: 700;
    font-size: 24px;
  }
  &_Title_Amount::before {
    content: 'w';
    background-color: #2196f3;
    color: #2196f3;
    margin-right: 6px;
  }
  &_Title_Exprise::before {
    content: 'w';
    background-color: #eb1f27;
    color: #eb1f27;
    margin-right: 6px;
  }
  #add_to_cart_button {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;
    background: #2196f3;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
}
