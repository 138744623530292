.GeneralControl {
  font-size: 14px;

  .selected-color {
    width: 20px;
    height: 20px;
  }

  .nav-tabs {
    background: #f0f2f5;
    border-radius: 6px;
    border-bottom: 0;
    padding: 5px;
    padding-bottom: 6px;

    .nav-item {
      button {
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        border-radius: 4px;
        padding: 4px 40px 4px 40px;
        color: black;

        &.active {
          font-weight: 700;
          background-color: #fff;
          box-shadow: 0 2px 4px 0 rgb(0 0 0/20%);
        }
      }
    }
  }

  .tab-content {
    width: 100%;
  }

  .fontsize-up,
  .fontsize-down,
  .lineheight-up,
  .lineheight-down {
    width: 36px;
    height: 36px;
    // background-color: #fff;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #cfd9e0;
    text-align: center;
    padding: 5px 0;
  }

  .lineheight-up,
  .lineheight-down {
    padding: 5px 3px 5px;
    div {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-clip: content-box;

      width: 25px;
      height: 25px;
    }
  }

  .custom-dd-toggle {
    background-color: #f3f6f9 !important;
    border-color: #e4e6ef !important;
  }

  .vat-dropdown {
    & > button:disabled {
      background-color: #f3f6f9;
      border-color: #e4e6ef;
      opacity: 1;
    }
  }
}
