.EmptyPackage {
  width: 100%;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  .text {
    color: #2c2c2c;
    font-size: 17px;
    font-weight: 600;
  }
}
