.CustomColorPicker {
  .dropdown {
    .dropdown-toggle {
      width: 100%;
      color: #495057 !important;
      text-align: start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }

    .dropdown-menu {
      width: 100%;
    }

    .selected-color {
      width: 20px;
      height: 20px;
    }
  }
}
