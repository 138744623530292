.required {
  &:after {
    color: red;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    content: '*';
  }
}
