.SalesContract {
  .redBox {
    width: 100%;
    height: 100%;
    padding: 10px 0;
    border: 1px solid #eb1f27;
    color: #eb1f27;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
  }
  .greenBox {
    width: 100%;
    height: 100%;
    border: 1px solid #34b214;
    color: #34b214;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
  }
  .blueBox {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #2196f3;
    color: #2196f3;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
  }
}
