.UnauthorizedScreen {
  .mainBox {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 700;
  }
  .imgBox {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
