.ticketCreateTableRow {
  height: 60px;
  color: #212529;
  line-height: 1.5;
  // background-color: red;
  .cellTicketName {
    font-size: 16px;
    font-weight: 600;
  }

  .cellTicketSerial {
    font-size: 14px;
  }
}
