.Search {
  font-size: 16px;

  .top-bar {
    padding: 1em;
  }

  .layout-tra-cuu {
    background-image: linear-gradient(to bottom right, #354aba, #44bbd1);
    padding: 60px 0;
  }

  .text-2xl {
    font-size: 1.5em !important;
  }

  .font-medium {
    font-weight: 500 !important;
  }

  .search-input {
    font-size: 1em;
    color: #495057;
    background: #ffffff;
    padding: 0.75em 0.75em;
    border: 1px solid #ced4da;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2em #c7d2fe;
      border-color: #6366f1;
    }
  }

  .layout-loi-ich > h2 {
    color: #2567b2;
    font-size: 1.75em;
    font-weight: 500;
  }

  .layout-loi-ich .item {
    border: 1px solid #2567b2;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px 30px;
  }

  .item .item-img {
    margin: 0 auto;
    width: 140px;

    img {
      max-width: 100%;
    }
  }

  .item .item-title {
    color: #2567b2;
    font-weight: 700;
    text-transform: uppercase;
  }

  .item .item-text {
    color: #8a8a8a;
    font-size: 15px;
    height: 60px;
  }

  .layout-footer {
    background-color: #3c7cc4;

    i {
      color: white;
    }
  }
}
