.Voucher {
  border: 1px solid #dbe3ef;
  /* shadow_dashboard */

  box-shadow: 0px 6px 14px rgba(33, 68, 115, 0.1);
  border-radius: 6px;
  .input-voucher {
    border: 1px solid #dbe3ef;
    border-radius: 4px;
    &:focus,
    &:active,
    &:focus-visible {
      border-color: rgba(22, 93, 200, 1);
      outline: rgba(22, 93, 200, 1);
    }
    margin-bottom: 0;
  }
  .price-voucher {
    font-weight: 400;

    line-height: 150%;
    /* identical to box height, or 18px */

    /* Dark Grey */

    color: #18214d;
  }
  .header-voucher {
    border-bottom: 1px solid #dbe3ef;
  }
}
