.custom-antd-select {
  & .ant-select-selector {
    padding: 0.25rem 1rem !important;
  }
}

.custom-antd-textarea {
  & .ant-input-data-count {
    left: 0;
  }
}
