.RouteControl {
  font-size: 14px;

  .route-item {
    border-top: 1px solid #ccc;

    &:last-child {
      border-bottom: 1px solid #ccc;
    }

    &.picked {
      background-color: #e7f3ff;

      & > i {
        color: #2680eb;
      }
    }
  }

  .btn-add-route {
    background-color: #e7f3ff;

    color: #5099ef;

    &:hover {
      background-color: #b8d8ff;
      color: #5099ef;
    }
  }
}
