.breadcrumb-contract {
  .breadcrumb {
    background-color: #fff;
    margin: 0;
    border-radius: unset;
    padding-left: 1.5rem;
  }
  .breadcrumb-item {
    // font-weight: 600;
    // font-size: 16px;
    // line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    // align-items: center;

    /* Feature */

    color: var(--remaining-color);
  }
  .breadcrumb-item a {
    color: var(--remaining-color);
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: var(--remaining-color);
  }
  .breadcrumb .breadcrumb-item:hover a {
    color: var(--remaining-color) !important;
    // opacity: 0.8;
  }
  .breadcrumb-item.active {
    // font-weight: 600;
    // font-size: 16px;
    // line-height: 24px;
    /* identical to box height, or 150% */

    /* Dashboard/Grey 80 */

    // color: #4a5677;
  }
}
