.ImportTicketStepTwo {
  .hupBbs {
    text-transform: capitalize;
  }

  .header-step {
    display: inline-block;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    background-color: #1877f2 !important;
    padding: 0 8px;
    line-height: 32px;
    cursor: pointer;
  }
}
