.ModalChooseAdjustTicketToCreateReport {
  .modal-dialog {
    width: 670px;
    max-width: 670px;
  }

  .modal-body {
    padding: 1rem 1.5rem 0;
    font-size: 14px;
    color: #333 !important;
    font-weight: 400;

    p {
      margin-bottom: 0.5rem;
    }
    .text-title {
      font-size: 20px;
      font-weight: 600;
    }

    .text-sub-title {
      font-style: italic;
    }
  }
  .modal-footer {
    border-top: 0;
    padding: 1.4rem 1.5rem;
  }

  .adjust-item {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #cecece;
    padding: 10px;
    width: 140px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-dark {
    font-weight: 700 !important;
  }

  .text-primary {
    color: #007bff !important;
  }
}
