@import '../fontawesome/css/all.css';

@keyframes loadingCircle {
  0% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) rotate(360deg);
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ant-message {
  display: flex !important;
  flex-direction: column-reverse !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  font-size: 1rem;
  & .ant-message-custom-content {
    max-width: 300px;
    display: inline-flex;
    justify-content: space-between;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}