.EInvoiceFile {
  .btn:disabled {
    opacity: 1;
    box-shadow: none;
  }
  .custom-select {
    color: #3f4254 !important;
  }
  .customBtn {
    display: flex;
    padding: 10px 0;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    outline: none;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    .textBox {
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 70%;
      white-space: nowrap;
      border-right: 1px solid #fff;
    }
    .iconBox {
      i {
        color: #fff !important;
      }
      justify-content: center;
      align-items: center;
      display: flex;
      width: 30%;
      height: 100%;
    }
  }
}
