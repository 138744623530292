.ModalShowAndPrintTickets {
  font-size: 14px;
  overflow-y: hidden !important;

  .modal-dialog {
    width: 550px;
    max-width: 100vw;
    margin: 0.25rem auto !important;

    .modal-content {
      height: 99vh;

      .modal-header {
        border-bottom: 0;

        .modal-title {
          font-size: 1.4rem !important;
          line-height: 1.4rem !important;
          font-weight: 700 !important;
        }

        .btn-icon {
          opacity: 0.5;
          height: inherit;

          &:hover {
            opacity: 1;
          }
        }
      }

      .modal-body {
        padding: 0;
        box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.5);

        .chevron-btn {
          border-radius: 50% !important;
          background-color: #d8d8d8;
          opacity: 0.6;

          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &:hover {
            opacity: 1;
          }
        }

        .chevron-left-btn {
          left: 10px;
        }
        .chevron-right-btn {
          right: 30px;
        }

        .notification {
          align-self: self-end;

          display: flex;
          align-items: center;

          font-size: 0.95em;
          background: #fffdc0;
          padding: 8px;
          border-radius: 6px;
          width: 490px;
          box-shadow: 1px 1px 4px rgb(0 0 0 / 48%);

          i {
            font-size: 20px;
          }
        }

        .paging {
          position: absolute;
          top: 90%;
          right: 30px;

          span {
            font-size: 1.1rem !important;
            padding: 0.5em 1.2em;
          }
        }
      }

      .modal-footer {
        border-top: 0;
        padding: 0.75rem 1rem;
      }
    }
  }
}
