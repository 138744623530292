.CreateTemplateInvoice {
  // background-color: #f0f0f0;

  .padding-x-89 {
    padding-left: 89px;
    padding-right: 89px;
  }

  .margin-x-89 {
    margin-left: 89px;
    margin-right: 89px;
  }

  .right-control {
    background: #fff;
    min-width: 350px;
    max-width: 471px;
    width: 465px;
    border: 1px solid #dedfe2;

    .form-control {
      border-radius: 0.42rem !important;
    }
  }

  .bg-light {
    background-color: #f0f2f5 !important;
  }

  .static-title {
    // font-family: 'SFPRODISPLAYBOLD';
    font-weight: 700;
  }

  .dynamic-title {
    font-size: 13px;
  }

  .region-active {
    background-color: #daeefb;
    outline: 1px dashed #1492e6;
  }

  .active-border-blue {
    &:active,
    &:hover {
      cursor: pointer;
      border: 1px solid blue !important;
    }
  }

  .dropdown {
    .dropdown-toggle {
      width: 100%;
      color: #495057 !important;
      text-align: start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      background-color: #fff;
      background-clip: padding-box !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:not(.dd-color-picker)::after {
        display: inline-block !important;
        color: #495057 !important;
        content: '';
        border-top: 0.5rem solid;
        border-right: 0.5rem solid transparent;
        border-bottom: 0;
        border-left: 0.5rem solid transparent;
      }

      &:hover,
      &:focus {
        background-color: inherit !important;
      }
    }

    .dropdown-menu {
      width: 100%;
    }
  }

  .custom-resizable-table {
    &.region-active {
      background-color: #daeefb;
      outline: 1px dashed #1492e6;

      table {
        border: 1px dotted blue !important;

        tr {
          border: 1px dotted blue !important;
        }

        th {
          border: 1px dotted blue !important;
        }

        td {
          border: 1px dotted blue !important;
        }
      }
    }

    &.realtable {
      table {
        border: 1px solid black;

        tr:last-child {
          td {
            border-bottom: 0 !important;
          }
        }

        th {
          border: 1px solid black;
        }

        td {
          border: 1px solid black;
          // border-left: 1px solid black;
          // border-right: 1px solid black;
        }
      }
    }

    table {
      color: inherit;
      overflow: hidden;
      border: 0;

      tr {
        border: 0;
      }

      th {
        padding: 0;

        border: 0;
        overflow: hidden;
        vertical-align: top;
        font-weight: normal;
      }

      td {
        vertical-align: top;
        border: 0;
      }
    }

    &.hide-tbody {
      & > div > div > table > tbody {
        display: none;
      }
    }

    &.vertical-align-middle {
      th,
      td {
        vertical-align: middle;
      }
    }

    &.hide-border {
      border: none !important;

      tr,
      th,
      td {
        border: none !important;
      }
    }
  }

  .active-data-field-l {
    background-color: #9ed2f6 !important;
  }

  .active-data-field-r {
    background-color: #daeefb !important;
  }

  .number-input {
    width: 60px;
    height: 2.5rem;
    text-align: right;
  }
}
