.btn-blue {
  color: #ffffff;
  padding: 9px 12px;
  background: #5180fb;
  // border: 1px solid #3465e5;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(162, 158, 176, 0.3);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #ffffff;
    font-weight: 500;
  }

  &:hover {
    background: #618cff;
  }
  &:active {
    background: #4a78f2;
  }
}

.btn-green {
  color: #ffffff;
  padding: 9px 12px;
  background: #3ace5a;
  border: 1px solid #2ab448;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(162, 158, 176, 0.3);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #ffffff;
    font-weight: 500;
  }

  &:hover {
    background: #4ddc6c;
  }
  &:active {
    background: #34c855;
  }
}
.btn-success {
  color: #ffffff;
  padding: 9px 12px;
  background: #00bbb3;
  border: 1px solid #00a7b2;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(162, 158, 176, 0.3);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #ffffff;
  }

  &:hover {
    background: #107e79;
  }
  &:active {
    background: #107e79;
  }
}
.btn-success-opacity {
  color: #28a043;
  padding: 9px 12px;
  background: rgba(40, 160, 67, 0.1);
  border: none;
  border-radius: 8px;

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #28a043;
  }

  &:hover {
    background: #dcfbe3;
  }
  &:active {
    background: #dcfbe3;
  }
}

.btn-red {
  color: #ffffff;
  padding: 9px 12px;
  background: #e54135;
  border: 1px solid #cd1f13;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #ffffff;
    font-weight: 500;
  }

  &:hover {
    background: #f35549;
  }
  &:active {
    background: #e13a2e;
  }
}
.btn-red-opacity {
  color: var(--secondary-red, #f44141);
  padding: 9px 12px;
  background: rgba(244, 65, 65, 0.05);
  border-radius: 8px;
  border: none;

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: var(--secondary-red);
    font-weight: 500;
  }

  &:hover {
    background: #fde2e2;
  }
  &:active {
    background: #fde2e2;
  }
}

.btn-grey {
  color: #4a5677;
  padding: 9px 12px;
  background: #f2f3f7;
  border: 1px solid #dbe3ef;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #4a5677;
    font-weight: 500;
  }

  &:hover {
    background: #f4f6fd;
  }
  &:active {
    background: #eeeff3;
  }
}

.btn-white {
  color: #4a5677;
  padding: 9px 12px;
  background: #ffffff !important;
  border: 1px solid #dbe3ef !important;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #4a5677;
    font-weight: 500;
  }

  &:hover {
    background: linear-gradient(180deg, #fff 0%, #fcfcff 100%) !important;
  }
  &:active {
    background: linear-gradient(180deg, #f8f9ff 0%, #f1f2f5 100%) !important;
  }
  &:focus {
    color: #4a5677 !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06) !important;
  }
}

.btn-turquoise {
  color: #ffffff;
  padding: 9px 12px;
  background: #00bbb3 !important;
  border: 1px solid #00a7b2 !important;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);

  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: default;
    }
  }
  i {
    color: #ffffff;
    font-weight: 500;
  }

  &:hover {
    background: #0ed1c8;
    border: 1px solid #07b6ae;
  }
  &:active {
    border: 1px solid #05ada5;
    background: #0cb8b1;
  }
}

.btn-playing {
  padding: 4px 8px;
  border-radius: 4px;
  border: 0px;
  color: #20a144;
  background: rgba(32, 161, 68, 0.1);
  i {
    color: #20a144;
    font-weight: 500;
  }
}
.btn-stop {
  padding: 4px 8px;
  border-radius: 4px;
  border: 0px;
  color: #e54135;
  background: rgba(229, 65, 53, 0.1);
  i {
    color: #e54135;
    font-weight: 500;
  }
}
.btn-normal {
  padding: 4px 8px;
  border-radius: 4px;
  border: 0px;
  color: #5180fb;
  background: rgba(81, 128, 251, 0.1);
  i {
    color: #5180fb;
    font-weight: 500;
  }
}
.btn-pause {
  padding: 4px 8px;
  border-radius: 8px;
  border: 0px;
  line-height: 8px;
  color: #e56e19;
  background: rgba(229, 110, 25, 0.1);
  i {
    color: #e56e19;
    font-weight: 500;
  }
}
