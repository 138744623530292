.ModalExportTemplate {
  font-size: 14px;

  .modal-header {
    border-bottom: 0;

    .modal-title {
      font-size: 1.4rem !important;
      line-height: 1.4rem !important;
      font-weight: 700 !important;
    }
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: 0;
  }
}
