.InvoiceApprovalProcess {
  .serialBox {
    width: 70px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    white-space: nowrap;
    width: fit-content;
    margin-bottom: 5px;
    margin-left: 5px;
    border: 1px solid #83d4ff;
  }
  .notLongtext {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .btn-icon-custom {
    background-color: transparent !important;
    color: #3f4254 !important;
    border: none !important;

    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    &:hover {
      background-color: #d1e6fe !important;
    }
  }
}
