// .customTimePicker {
//   .ant-picker {
//     .ant-picker-input{

//     }
.ant-picker-footer {
  display: none;
}
//   }
// }
