.nfe-container {
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0 0 0 6px;
  color: #ff5757;
  border-radius: 6px;

  .nfe-wrapper {
    padding: 1.25em 1.75em;
  }
}
