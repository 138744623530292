.left-content {
  .lc-frame {
    overflow: hidden;
    position: relative;
    background-color: rgb(255, 255, 255);

    .lc-frame-surround {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      & > div {
        width: 100%;
        height: 100%;
      }
    }

    .lc-frame-background {
      .icon-gripsmall {
        width: 10px;
        height: 10px;
        background-color: blue;

        position: absolute;
        top: 100%;
        left: 100%;
        transform: translate(-65%, -65%);
      }
    }

    .lc-frame-watermark {
      width: 300px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;

      background-repeat: no-repeat;
      background-size: contain;
    }

    .lc-frame-content {
      // width: calc(100% - 30pt * 2);
      // padding-top: 30pt;
      // padding-bottom: 30pt;
      // margin-left: 30pt;
      // margin-right: 30pt;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      z-index: 1;

      .lc-frame-content-detail {
        .react-resizable-handle-se {
          cursor: ew-resize;
        }

        .react-resizable-handle {
          background-image: initial;
          width: 1px;
          height: 100%;
        }

        .seller-info {
          white-space: nowrap;
        }

        .horizontal-content {
          display: flex;
          flex-direction: row;
          align-items: stretch;
        }

        .vertical-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .wh-100 {
          position: absolute;
          inset: 0;
        }

        .edit-label,
        .edit-label-en,
        .edit-value,
        .two-dot {
          /* span */
          &.hidden-span {
            display: inline-block;
            visibility: hidden;
          }
        }

        .tax-code-input {
          .tax-code-number:first-child {
            border-left: 1px solid #ccc;
          }
          .tax-code-number {
            width: 20px;
            text-align: center;
            border: 1px solid #ccc;
            justify-content: center;
            border-left: none;
          }
        }

        .same-width-child {
          flex: 1 1 0;
        }

        .invoice-other-info {
          border-collapse: collapse;

          tr td:first-child {
            white-space: nowrap;
          }

          tr td:last-child {
            width: 100%;
          }
        }

        .tableFooter {
          td:nth-child(odd) {
            border-right: 0;
            border-left: 0;
          }
          td:nth-child(even) {
            border-left: 0;
            border-right: 0;
          }

          td:first-child {
            padding-left: 2pt;
          }
        }

        .tableFooter1 {
          td:first-child {
            padding-left: 2pt;
          }
        }

        .tableFooter1.type-2 {
          td:nth-child(odd) {
            border-right: 0;
          }
          td:nth-child(even) {
            border-left: 0;
          }
        }
      }
    }
  }
}
