.CardInvoiceItem {
  //   width: 442.67px;
  // height: 190px;
  background: #fff;
  box-shadow: 0px 6px 14px rgba(33, 68, 115, 0.1);
  border-radius: 8px;
  padding: 15px;
  .title {
    color: #495057;
    font-weight: 400;
  }
  .textBx {
    color: #495057;
    font-weight: 500;
    font-size: 13px;
    i {
      color: #2196f3;
    }
  }
  .contentBx {
    color: #4570fe;
    font-weight: 700;
    font-size: 13px;
  }
  .btn1 {
    border: none;
    outline: none;
    height: 40px;
    background: #4570fe;
    color: #fff;
    border: 1px solid #4570fe;
    border-radius: 6px;
    font-weight: 700;
    transition: 0.3s;
    &:hover {
      color: #fff;
      background: #4570fe;
    }
  }
  .btn2 {
    border: none;
    outline: none;
    height: 40px;
    background: #fff;
    color: #4570fe;
    border: 1px solid #4570fe;
    border-radius: 6px;
    font-weight: 700;
    transition: 0.3s;
    &:hover {
      color: #fff;
      background: #4570fe;
    }
  }
}
