.IssueInvoiceScreen {
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none !important;
  }
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    border-top: 1px solid #f0f0f0;
  }

  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px !important;
  }

  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table
    .ant-table-title,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table
    .ant-table-header {
    border-radius: 0px !important;
  }

  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    background: #ffffff !important;
  }

  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    tfoot
    > tr
    > th,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    tfoot
    > tr
    > td {
    padding: 10px 10px;
  }

  .ant-table-measure-row > td {
    padding: 0px !important;
  }

  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper .ant-table {
    font-family: 'Inter';
  }

  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-htwhyh).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    font-weight: 600 !important;
    font-size: 0.9rem !important;
    color: rgb(60, 67, 118) !important;
  }
}
