.FolderTree {
  input[type='radio'] {
    accent-color: var(--remaining-color) !important;
    transform: scale(1.5);
  }

  .accordion-button {
    &::after {
      margin-left: 0px !important;
    }
  }

  .modal-body {
    height: 43vh;
    overflow: auto;
    position: relative;
  }

  .input-organization {
    position: relative;
    padding: 4px;
    .fa-search {
      position: absolute;
      top: 35%;
      left: 4%;
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #9ea5bd;
      cursor: pointer;
    }
    input {
      width: 100%;
      background: #ffffff;
      border: 1px solid #dbe3ef;
      border-radius: 4px;
      padding: 8px 36px;
      &:focus-visible {
        outline: none !important;
        outline-color: #fff !important;
        outline-style: none !important;
        outline-width: 0px !important;
      }
    }
  }
  .accordion-items {
    cursor: pointer;
    border-top: 1px solid #dbe3ef;
    // width: 100%;
    .accordion {
      background-color: #fff !important;

      .accordion-item {
        width: 100%;
        border: none;
        position: relative;
        .accordion-header {
          border-bottom: none !important;
        }
        .accordion-name {
          position: absolute;
          top: 10%;
          // width: 300px;
          display: flex;
          flex-direction: row;
          // left: 100%;
          margin-left: 20px;
          margin-top: 7px;

          .name-level-icon {
            .fas,
            .fal {
              &:hover {
                color: var(--remaining-color);
              }
            }
          }
          .form-check-label {
            margin-left: 20px;
            width: 250px;
          }

          p {
            margin-bottom: 0px;
            margin-left: 1.5rem;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            display: flex;
            align-items: center;
            color: #4a5677;
            // width: 200px;
          }
        }
        &:hover {
          .name-level-icon {
            display: block !important;
          }
        }

        .name-level-icon {
          position: absolute;
          right: 45px;
          z-index: 9;
          top: 13px;

          .fas,
          .fal {
            &:hover {
              color: var(--remaining-color);
            }
          }
        }

        .form-check-input {
          z-index: 9;
          top: 12px;
          font-size: 15px;
          margin-top: -1px;
          position: absolute;
          right: 15px;
          cursor: pointer;
          &:checked {
            color: var(--remaining-color);
            background-color: var(--remaining-color);
            border-color: var(--remaining-color);
          }
          &:focus {
            box-shadow: none !important;
            border-color: #9ea5bd;
          }
        }
      }
    }
  }
  .accordion-button {
    font-style: normal;
    font-weight: 600;

    line-height: 17px;
    color: #4a5677 !important;

    &:focus {
      box-shadow: none !important;
    }
    &::after {
      background-image: url('../../../../assets/icons/square-plus.svg');
      background-size: 16px 17px;
    }
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url('../../../../assets/icons/square-minus.svg');
    background-size: 16px 17px;
    transform: revert;
  }
  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
    box-shadow: none !important;
  }
  .accordion-header {
    border-bottom: 1px solid #dbe3ef;
  }
  .accordion-body {
    padding: 0;
    .accordion-share {
      border-top: 1px solid #dbe3ef;
      display: flex;
      padding: 10px 0;
      position: relative;
      padding-left: 42px;
    }
  }
}
