.ListCart {
  animation: fadeIn 0.2s;
  border: 1px solid #dbe3ef;
  overflow: auto;
  /* shadow_dashboard */

  box-shadow: 0px 6px 14px rgba(33, 68, 115, 0.1);
  border-radius: 6px;
  .CartHeader {
    border-bottom: 1px solid #dbe3ef;
    p {
      min-width: max-content;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* Dark Grey */

      color: #18214d;
    }
  }
  th {
    padding: 1rem 1.5rem !important;
  }
  .table > :not(caption) > * > * {
    border: unset;
  }
}
